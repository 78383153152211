/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W latach 2021-2022 Transpol Lider Sp. z o.o. Sp.k. realizował inwestycję budowy ścieżki rowerowej wzdłuż drogi powiatowej 3189P między Skulskiem a Mielnicą Dużą (województwo wielkopolskie)."), "\n", React.createElement(_components.p, null, "Zakres rzeczowy robót obejmował między innymi:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "budowę nawierzchni ścieżki rowerowej z betonu asfaltowego;"), "\n", React.createElement(_components.li, null, "osadzenia krawężników i obrzeży betonowych na ławie betonowej z oporem"), "\n", React.createElement(_components.li, null, "budowę zjazdów z betonowej kostki brukowej oraz z kruszywa kamiennego,"), "\n", React.createElement(_components.li, null, "budowę rowów odwadniających;"), "\n", React.createElement(_components.li, null, "montaż kanałów oraz przepustów,"), "\n", React.createElement(_components.li, null, "montaż wiaty rowerowej oraz barierek ochronnych,"), "\n", React.createElement(_components.li, null, "wykonanie oznakowania pionowego oraz poziomego."), "\n"), "\n", React.createElement(_components.p, null, "Prace zostały wykonane w sposób należyty, zgodnie z projektem oraz sztuką budowlaną. Ścieżka została oddana do użytku w październiku 2022 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
